<template>
  <div class="conteudos_tematicos_bg">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="mt-md-5">
          <p
            style="background-color: #EDBD43; padding: 0.5em; border-radius: 4px; font-size: 1em;"
          >
            <strong> Auxílio Financeiro Emergencial </strong>
          </p>
        </div>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p class="pa-3 mb-2 my-auto" style="background-color: #F3F3F3;">
                  “A empresa não resolve nada, só nos coloca na posição de
                  mendigar um cartão emergencial. E é diferente ser mulher
                  atingida, não há mecanismos voltados especificamente para a
                  escuta de atingidas mulheres. Assim como não existe também uma
                  política protetiva aos direitos dos vulnerabilizados dentro da
                  estrutura patriarcal em que a gente vive. Não colocam a mulher
                  como responsável financeira. Eu me sinto fragilizada nesse
                  processo de reparação (Eliane Balke, atingida, 2020).”
                </p>
                <p>
                  São as próprias mulheres, vítimas do crime ambiental, que
                  relatam que o cartão de auxílio financeiro emergencial, por
                  exemplo, é reiteradamente fornecido ao homem, conforme é
                  possível expressar no gráfico abaixo<sup>1</sup>, o que acaba
                  por demonstrar que a Fundação Renova não entende o lugar
                  ocupado pela mulher na sociedade, que é de centralidade e de
                  arrimo de família<sup>2</sup>. A Cáritas já realizou diversos
                  atendimentos em que as atingidas afirmam que sequer sabem se o
                  marido recebe ou não o auxílio, e há casos em que as mulheres,
                  muitas em situação de grande vulnerabilidade financeira e
                  dependência patrimonial, expressam explicitamente o receio de
                  realizarem as solicitações em seus nomes ou acesso a parte do
                  recurso.
                </p>
                <p>
                  A concessão do auxílio emergencial em sua grande parte
                  destinada ao homem traz consigo diversos desdobramentos. Os
                  20% do valor total previstos para serem destinados aos
                  dependentes do titular do cartão nem sempre são repassados e,
                  em casos de separação, os casos acompanhados pela Cáritas
                  demonstram, na maioria das vezes, que o progenitor permanece
                  com a porcentagem referente aos filhos e ex-companheira.
                  Muitas vezes, é apenas após a separação que as atingidas,
                  menos coagidas, conseguem lutar pela garantia do auxílio
                  emergencial referente à perda de renda individual. No entanto,
                  o processo da mulher requerer a parte que lhe cabe, não
                  mediada pela Fundação Renova, é extremamente desgastante e
                  culmina na desistência até mesmo de requerer a parte referente
                  aos filhos, que na separação tendem a ficar aos cuidados da
                  mãe.
                </p>
                <p>
                  Outro desdobramento da desigualdade na concessão é o
                  acirramento de conflitos, incluindo situações de violência
                  doméstica, que muitas vezes têm como consequência a separação
                  do casal. Nas comunidades rurais, era muito comum que a mulher
                  trabalhasse junto ao companheiro na lavoura e venda dos
                  produtos produzidos na agricultura familiar. Aquelas que não
                  contribuíam nessa atividade laborativa, quase sempre possuíam
                  algum outro trabalho. Todavia, na entrega inicial do processo
                  de concessão dos auxílios emergenciais, muitas vezes essa
                  realidade foi desconsiderada, restando a opção somente de
                  conseguir o reconhecimento através da judicialização.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_09.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Cáritas Brasileira Regional Minas Gerais.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A denúncia da entrega do cartão do Auxílio Financeiro
                  Emergencial ao homem destoa, em grande medida, da política de
                  pagamento da renda básica pelo Governo Federal na era do
                  extinto e louvável projeto Bolsa Família que ajudou famílias
                  brasileiras por 18 anos. A título exemplificativo, nesse
                  sistema, o cartão era entregue preferencialmente à mulher,
                  sendo que, em 2016, quando o rompimento já havia acontecido e
                  as famílias já recebiam auxílio da Samarco, das 14 milhões de
                  famílias no Brasil que eram beneficiadas pelo Bolsa Família,
                  92% dos titulares eram mulheres (MINISTÉRIO DA CIDADANIA,
                  2016).
                </p>
                <p>
                  O contraste é evidente: enquanto o Bolsa Família deu
                  visibilidade às mulheres beneficiárias e mais acesso aos seus
                  direitos, a política indenizatória da Samarco parece buscar os
                  homens para tratativa de direitos, desconsiderando a mulher. A
                  experiência do Bolsa Família demonstra que a renda sendo
                  destinada à mulheres evita situações de violência doméstica,
                  além de permitir maior liberdade para a mulher no cuidado e
                  criação dos filhos.
                </p>
                <p>
                  A pescadora Eliane Balke resume essa situação: “é muito
                  triste, como mulher atingida, ver o modelo de mitigação e
                  indenização proposto para as atingidas do rio Doce não seguir
                  diretrizes de amparo à mulher, como acontece, por exemplo, nos
                  programas do Governo Federal, como o Bolsa Família, no qual as
                  mulheres são titulares preferenciais aos benefícios” (BALKE,
                  2020).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_10.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Pinto. Jornal A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Os dados consideram a documentação nominal produzida
              de 2019 a 2020 pela Cáritas Brasileira Regional Minas Gerais,
              mediante solicitação dos requerentes, com o objetivo de compor
              requisição de judicialização dos Auxílios Financeiros em Mariana.
              Não foi possível encontrar dados de gênero com relação aos
              benefícios concedidos pela Fundação Renova.
            </p>
            <p>
              <sup>2</sup> Essa fala foi feita pela atingida em reunião de
              entrega individual de Parecer Jurídico e Documento de Valoração,
              realizada entre a assessoria jurídica e a família, motivo pelo
              qual não há documentos públicos em que haja menção a esse diálogo.
              Ademais, cumpre reforçar que as análises aqui esboçadas
              sustentam-se nas percepções e reflexões elaboradas pela própria
              Cáritas MG durante os mais de quatro anos de trabalho em Mariana
              com os atingidos e atingidas pelo rompimento da Barragem de
              Fundão, em que foi possível observar padrões e recorrências.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Gênero /",
          href: "conteudos_tematicos_genero",
        },

        {
          text: "Auxílio Financeiro Emergencial",
          href: "conteudos_tematicos_auxilio_financeiro",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
